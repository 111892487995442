<p-toast
	key="tst"
	[baseZIndex]="99999"></p-toast>

<ng-template [ngIf]="loading">
	<div class="flex justify-content-center flex-wrap">
		<p-progressSpinner></p-progressSpinner>
	</div>
</ng-template>

<ng-template [ngIf]="!loading">
	<div class="card">
		<p-table
			#tblCalendars
			[value]="hollyDaysCalendar"
			[paginator]="true"
			[rows]="10"
			[rowHover]="true"
			[showCurrentPageReport]="true"
			responsiveLayout="scroll"
			styleClass="p-datatable-striped"
			[globalFilterFields]="['id', 'entityCalendarShortName', 'entityCalendarName']"
			currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
			[tableStyle]="{ 'min-width': '25rem' }"
			[rowsPerPageOptions]="[10, 25, 50]">
			<ng-template pTemplate="caption">
				<div class="flex flex-wrap gap-2 align-items-center justify-content-between">
					<span class="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
						<i class="pi pi-search"></i>
						<input
							pInputText
							type="text"
							(input)="onGlobalFilter(tblCalendars, $event)"
							placeholder="Global Search"
							class="w-full" />
					</span>
					<button
						(click)="navigateToCreatecalendar()"
						pButton
						class="p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
						icon="fa-solid fa-calendar-plus"
						label="Add New"></button>
				</div>
			</ng-template>

			<ng-template pTemplate="header">
				<tr>
					<th
						pSortableColumn="id"
						class="white-space-nowrap"
						style="width: 20%">
						Id<p-sortIcon field="id"></p-sortIcon>
					</th>
					<th
						pSortableColumn="entityCalendarShortName"
						class="white-space-nowrap"
						style="width: 20%">
						Short Name<p-sortIcon field="entityShortname"></p-sortIcon>
					</th>

					<th
						pSortableColumn="entityName"
						class="white-space-nowrap"
						style="width: 60%">
						Name<p-sortIcon field="entityCalendarName"></p-sortIcon>
					</th>
					<th>Action</th>
				</tr>
			</ng-template>
			<ng-template
				pTemplate="body"
				let-hollyDaysCalendars>
				<tr>
					<td>{{ hollyDaysCalendars.id }}</td>
					<td>{{ hollyDaysCalendars.entityCalendarShortName }}</td>
					<td>{{ hollyDaysCalendars.entityCalendarName }}</td>
					<td>
						<div class="flex">
							<p-button
								[routerLink]="['manage', hollyDaysCalendars.id]"
								icon="fa-solid fa-pen-to-square"></p-button>
						</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</ng-template>
