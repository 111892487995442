<p-toast
	key="tst"
	[baseZIndex]="99999"></p-toast>
<ng-template [ngIf]="loading">
	<div class="flex justify-content-center flex-wrap">
		<p-progressSpinner></p-progressSpinner>
	</div>
</ng-template>
<ng-template [ngIf]="!loading">
	<div class="grid">
		<div
			class="col-fixed"
			style="width: auto">
			<div class="text-center mt-3 border-round-sm">
				<p-calendar
					class="max-w-full"
					[inline]="true"
					[showWeek]="false"
					[defaultDate]="defaultDate"
					(onSelect)="onSelect($event)"
					(onMonthChange)="onMonthChange($event)"
					(onYearChange)="onYearChange($event)">
					<ng-template
						pTemplate="date"
						let-date>
						<span
							[ngStyle]="{
								color: isWeekendOrHolidayDate(date.year, date.month, date.day)
									? 'red'
									: 'black',
							}">
							{{ date.day }}
						</span>
					</ng-template>
				</p-calendar>
			</div>
		</div>
		<div class="col mt-3">
			<div class="text-center">
				<form [formGroup]="hollyDateForm">
					<input
						formControlName="id"
						name="id"
						id="id"
						type="text"
						pInputText
						hidden />
					<input
						formControlName="year"
						name="year"
						id="year"
						type="text"
						pInputText
						hidden />
					<input
						formControlName="entityCalendar"
						name="entityCalendar"
						id="entityCalendar"
						type="text"
						pInputText
						hidden />

					<input
						formControlName="month"
						name="month"
						id="month"
						type="text"
						pInputText
						hidden />
					<input
						formControlName="day"
						name="day"
						id="day"
						type="text"
						pInputText
						hidden />
					<div class="grid formgrid p-fluid">
						<div class="field col-12 md:col-6">
							<label for="firstname6">Holly Date</label>

							<p-calendar
								formControlName="hollydayCalendarDate"
								name="hollydayCalendarDate"
								id="hollydayCalendarDate"
								dateFormat="yy-mm-dd"
								(onSelect)="onSelect($event)" />

							<div
								*ngIf="
									(formControl('hollydayCalendarDate')?.touched || submitted) &&
									formControl('hollydayCalendarDate')?.errors?.['required']
								">
								<small
									id="hollydayCalendarDate-1"
									class="p-error block"
									>Please set a valid date
								</small>
							</div>
						</div>
						<div class="field col-12 md:col-6">
							<label for="lastname6">Description</label>
							<input
								formControlName="description"
								name="description"
								id="description"
								type="text"
								class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
							<div
								*ngIf="
									(formControl('description')?.touched || submitted) &&
									formControl('description')?.errors?.['required']
								">
								<small
									id="description-1"
									class="p-error block"
									>Description is requerid
								</small>
							</div>

							<div
								*ngIf="
									(formControl('description')?.touched || submitted) &&
									formControl('description')?.errors?.['maxlength']
								">
								<small
									id="description2err"
									class="p-error block"
									>Max length is 100</small
								>
							</div>
							<div
								*ngIf="
									(formControl('description')?.touched || submitted) &&
									formControl('description')?.hasError('pattern')
								">
								<small
									id="descriptionPatter"
									class="p-error block"
									>Alphabetic characters only, blank spaces can be used</small
								>
							</div>
						</div>

						<div class="field mb-4 col-12 md:col-3">
							<button
								(click)="saveHollyDate()"
								pButton
								pRipple
								label="Save"></button>
						</div>

						<div class="field mb-4 col-12 md:col-3">
							<button
								(click)="deleteHollyDate()"
								pButton
								pRipple
								label="Delete"></button>
						</div>
						<div class="field mb-4 col-12 md:col-3">
							<button
								pButton
								pRipple
								label="Import"></button>
						</div>
						<div class="field mb-4 col-12 md:col-3">
							<button
								pButton
								pRipple
								label="Export"></button>
						</div>
					</div>
				</form>
				<div class="text-center m-2 border-round-sm bg-primary font-bold">
					<p-table
						#tblHollyDays
						[value]="hollyDaysCalednar"
						[paginator]="true"
						[rows]="10"
						[rowHover]="true"
						[showCurrentPageReport]="true"
						responsiveLayout="scroll"
						[loading]="loading"
						styleClass="p-datatable-striped"
						[globalFilterFields]="['hollydayCalendarDate', 'description']"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
						[tableStyle]="{ 'min-width': '25rem' }"
						[rowsPerPageOptions]="[10, 25, 50]">
						<ng-template pTemplate="header">
							<tr>
								<th
									pSortableColumn="hollydayCalendarDate"
									class="white-space-nowrap">
									Short Name<p-sortIcon field="hollydayCalendarDate"></p-sortIcon>
								</th>

								<th
									pSortableColumn="description"
									class="white-space-nowrap">
									Description<p-sortIcon field="description"></p-sortIcon>
								</th>
							</tr>
						</ng-template>
						<ng-template
							pTemplate="body"
							let-hollyDaysCalendars>
							<tr>
								<td>{{ hollyDaysCalendars.hollydayCalendarDate }}</td>
								<td>{{ hollyDaysCalendars.description }}</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
		</div>
	</div>
</ng-template>
