import { Component, OnInit } from '@angular/core';
import { CalendarService } from '../services/calendar.service';
import { Calendars } from '../interfaces/calendars';
import { catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Router } from '@angular/router';

@Component({
	selector: 'app-holly-days-calendar',
	templateUrl: './holly-days-calendar.component.html',
	styleUrl: './holly-days-calendar.component.scss',
	providers: [MessageService],
})
export class HollyDaysCalendarComponent implements OnInit {
	page: number = 0;
	size: number = 300;
	sortBy: string = 'yes';
	sortDirection: number = 1;
	loading: boolean = true;

	hollyDaysCalendar: Calendars[] = [];

	constructor(
		private calendarService: CalendarService,
		private messageService: MessageService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.calendarService
			.getAllCalendars(this.page, this.size, this.sortBy, this.sortDirection)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this.messageService.add({
						key: 'tst',
						severity: 'error',
						summary: error.error.httpStatus,
						detail: error.error.message,
					});
					this.loading = false;
					return of(undefined);
				})
			)
			.subscribe((response) => {
				if (response) {
					this.hollyDaysCalendar = response.body.content;
				}
				this.loading = false;
			});
	}

	onGlobalFilter(table: Table, event: Event): void {
		table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
	}

	navigateToCreatecalendar(): void {
		this.router.navigate(['entities/entity-setup/hollydays/manage']);
	}
}
