import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HollyDaysCalendarComponent } from './holly-days-calendar/holly-days-calendar.component';
import { AuthGuard } from '../../../utils/guards/auth.guard';
import { HollyDaysCalendarFormComponent } from './holly-days-calendar-form/holly-days-calendar-form.component';

const routes: Routes = [
	{
		path: '',
		component: HollyDaysCalendarComponent,
		data: { allowedRoles: ['FO_EntitySetUp'] },
		canActivate: [AuthGuard],
	},
	{
		path: 'manage',
		component: HollyDaysCalendarFormComponent,
		data: { allowedRoles: ['FO_EntitySetUp'] },
		canActivate: [AuthGuard],
	},
	{
		path: 'manage/:calendarId',
		component: HollyDaysCalendarFormComponent,
		data: { allowedRoles: ['FO_EntitySetUp'] },
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class HollyDaysCalendarRoutingModule {}
