import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultResponse } from '../../../../utils/interfaces/default-response';
import { Calendars } from '../interfaces/calendars';
import { environment } from '../../../../../environments/environment';
import { PageResponse } from '../../../../utils/interfaces/page-response';
import { FormGroup } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class CalendarService {
	constructor(private httpClient: HttpClient) {}

	getAllCalendars(
		page: number,
		size: number,
		sortBy: string,
		sortDirection: number
	): Observable<DefaultResponse<PageResponse<Calendars>>> {
		const options = {
			params: new HttpParams()
				.set('page', page)
				.set('size', size)
				.set('sortBy', sortBy)
				.set('sortDirection', sortDirection),
		};

		return this.httpClient.get<DefaultResponse<PageResponse<Calendars>>>(
			environment.ok_backend.concat('/entity-setup/calendar'),
			options
		);
	}

	getCalendarByI(id: string): Observable<DefaultResponse<Calendars>> {
		return this.httpClient.get<DefaultResponse<Calendars>>(
			environment.ok_backend.concat('/entity-setup/calendar/').concat(id)
		);
	}

	saveCalendar(calendarForm: FormGroup): Observable<DefaultResponse<Calendars>> {
		return this.httpClient.post<DefaultResponse<Calendars>>(
			environment.ok_backend.concat('/entity-setup/calendar'),
			calendarForm.value
		);
	}
}
