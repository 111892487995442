import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultResponse } from '../../../../utils/interfaces/default-response';
import { HollyDaysCalednar } from '../interfaces/holly-days-calednar';
import { environment } from '../../../../../environments/environment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Calendars } from '../interfaces/calendars';
import { HolyDateRequest } from '../interfaces/holy-date-request';

@Injectable({
	providedIn: 'root',
})
export class HollyDaysCalendarService {
	url = `${environment.ok_backend}/entity-setup/calendar/holly-days`;

	constructor(
		private httpClient: HttpClient,
		private formBuilder: FormBuilder
	) {}

	getAllHollyDaysByYearAndMonth(
		year: number,
		month: number,
		entityCalendar: Calendars
	): Observable<DefaultResponse<HollyDaysCalednar[]>> {
		const request: HolyDateRequest = {
			year: year,
			month: month,
			entityCalendar: entityCalendar,
		};

		let form: FormGroup = this.formBuilder.group({
			year: new FormControl(undefined),
			month: new FormControl(undefined),
			entityCalendar: new FormControl(undefined),
		});

		form.setValue(request);

		return this.httpClient.post<DefaultResponse<HollyDaysCalednar[]>>(
			this.url.concat('/byYearAndMonth'),
			form.value
		);
	}

	saveHollyDate(formGroup: FormGroup): Observable<DefaultResponse<HollyDaysCalednar[]>> {
		return this.httpClient.post<DefaultResponse<HollyDaysCalednar[]>>(
			environment.ok_backend.concat('/entity-setup/calendar/holly-days'),
			formGroup.value
		);
	}

	deleteHollyDate(id: number): Observable<DefaultResponse<HollyDaysCalednar[]>> {
		return this.httpClient.delete<DefaultResponse<HollyDaysCalednar[]>>(
			environment.ok_backend.concat('/entity-setup/calendar/holly-days/').concat(String(id))
		);
	}
}
