<p-toast
	key="tst"
	[baseZIndex]="99999"></p-toast>
<ng-template [ngIf]="loading">
	<div class="flex justify-content-center flex-wrap">
		<p-progressSpinner></p-progressSpinner>
	</div>
</ng-template>
<ng-template [ngIf]="!loading">
	<form [formGroup]="calendarForm">
		<input
			formControlName="id"
			name="id"
			id="id"
			type="text"
			pInputText
			hidden />
		<span class="text-900 text-xl font-bold mb-4 block">Calendar create/update process</span>
		<div class="grid flex flex-wrap justify-content-center gap-3">
			<div class="col-12 lg:col-10">
				<div class="grid formgrid p-fluid">
					<div class="field mb-4 col-6">
						<label
							for="entityCalendarShortName"
							class="font-medium text-900"
							>Short Name
						</label>
						<input
							formControlName="entityCalendarShortName"
							name="entityCalendarShortName"
							id="entityCalendarShortName"
							type="text"
							pInputText />
						<div
							*ngIf="
								(formControl('entityCalendarShortName')?.touched || submitted) &&
								formControl('entityCalendarShortName')?.errors?.['required']
							">
							<small
								id="shortNameError"
								class="p-error block"
								>Please select a valid short name
							</small>
						</div>
						<div
							*ngIf="
								formControl('entityCalendarShortName')?.touched &&
								formControl('entityCalendarShortName')?.errors?.['pattern']
							">
							<small
								id="branchShortname2eqrr"
								class="p-error block"
								>Invalid characters</small
							>
						</div>
					</div>
					<div class="field mb-4 col-6">
						<label
							for="entityCalendarName"
							class="font-medium text-900"
							>Name
						</label>
						<input
							formControlName="entityCalendarName"
							name="entityCalendarName"
							id="entityCalendarName"
							type="text"
							pInputText />
						<div
							*ngIf="
								(formControl('entityCalendarName')?.touched || submitted) &&
								formControl('entityCalendarName')?.errors?.['required']
							">
							<small
								id="shortNameError"
								class="p-error block"
								>Please select a valid name
							</small>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 lg:col-10">
				<div class="grid formgrid p-fluid">
					<div class="field mb-4 col-12 md:col-4">
						<button
							(click)="createOrUpdateCalendar()"
							pButton
							pRipple
							[disabled]="!enableUpdateButton"
							label="Create Calendar"></button>
					</div>

					<div class="field mb-4 col-12 md:col-4">
						<button
							(click)="createOrUpdateCalendar()"
							pButton
							pRipple
							[disabled]="enableUpdateButton"
							label="Update Calendar"></button>
					</div>
					<div class="field mb-4 col-12 md:col-4">
						<button
							(click)="backTolist()"
							pButton
							pRipple
							label="Back to list"></button>
					</div>
				</div>
			</div>
		</div>
	</form>
</ng-template>
