import { Component, input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CalendarMonthChangeEvent, CalendarYearChangeEvent } from 'primeng/calendar';
import { WeekendCalendarDaysService } from '../services/weekend-calendar-days.service';

import { WeekEndCalednarDays } from '../interfaces/week-end-calednar-days';
import { HollyDaysCalendarService } from '../services/holly-days-calendar.service';
import { HollyDaysCalednar } from '../interfaces/holly-days-calednar';
import { catchError, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Calendars } from '../interfaces/calendars';

@Component({
	selector: 'app-holly-calendar-list',
	templateUrl: './holly-calendar-list.component.html',
	styleUrl: './holly-calendar-list.component.scss',
	providers: [MessageService],
})
export class HollyCalendarListComponent implements OnInit, OnChanges {
	weekEndDays: WeekEndCalednarDays[] = [];
	hollyDaysCalednar: HollyDaysCalednar[] = [];
	loading: boolean = true;
	submitted = false;
	hollyDateForm!: FormGroup;
	calendars = input.required<Calendars>();

	defaultDate!: Date;
	viewDate!: Date;

	constructor(
		private weekendCalendarDaysService: WeekendCalendarDaysService,
		private hollyDayCalendarService: HollyDaysCalendarService,
		private messageService: MessageService,
		private formBuilder: FormBuilder
	) {}
	ngOnChanges(changes: SimpleChanges): void {
		if (this.calendars() !== undefined) {
			const date = new Date();
			this.createForm();
			this.formControl('entityCalendar')?.setValue(this.calendars());
			this.loadHollyDaysAndWeekends(date.getFullYear(), date.getMonth() + 1);
		}
	}

	ngOnInit(): void {
		const date = new Date();
		this.createForm();

		if (this.calendars() !== undefined) {
			this.loadHollyDaysAndWeekends(date.getFullYear(), date.getMonth() + 1);
		}
		this.loading = false;
	}

	onSelect(event: Date): void {
		let hollyDateCalendar = this.hollyDaysCalednar.find(
			(holiday: HollyDaysCalednar) =>
				holiday.year === event.getFullYear() &&
				holiday.month === event.getMonth() + 1 &&
				holiday.day === event.getDate()
		);

		const hollyDateNew: HollyDaysCalednar = {
			id: 0,
			hollydayCalendarDate: event,
			day: event.getDate(),
			month: event.getMonth() + 1,
			year: event.getFullYear(),
			description: '',
			entityCalendar: this.calendars(),
		};

		this.hollyDateForm.setValue(
			hollyDateCalendar === undefined ? hollyDateNew : hollyDateCalendar
		);
		//this.hollyDateForm.get('hollydayCalendarDate')?.setValue(new Date(hollyDateCalendar?.hollydayCalendarDate ?? event));
	}

	formatDate(date: Date): string {
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');

		return `${year}-${month}-${day}`;
	}

	onMonthChange(event: CalendarMonthChangeEvent): void {
		this.loadHollyDaysAndWeekends(event.year ?? 0, event.month ?? 0);
	}

	onYearChange(event: CalendarYearChangeEvent): void {
		this.loadHollyDaysAndWeekends(event.year ?? 0, event.month ?? 0);
	}

	private loadHollyDaysAndWeekends(year: number, month: number): void {
		this.hollyDaysCalednar = [];
		this.hollyDayCalendarService
			.getAllHollyDaysByYearAndMonth(year ?? 0, month ?? 0, this.calendars())
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this.handleError(error);
					this.loading = false;
					return of(undefined);
				}),
				switchMap((hollyDays) => {
					if (hollyDays === undefined) return of(undefined);

					this.hollyDaysCalednar = hollyDays.body;
					return this.weekendCalendarDaysService.getAllWeekEndDaysByDate().pipe(
						catchError((error: HttpErrorResponse) => {
							this.handleError(error);
							this.loading = false;
							return of(undefined);
						})
					);
				})
			)
			.subscribe((response) => {
				if (response) {
					this.weekEndDays = response.body;
				}

				this.loading = false;
			});
	}

	isWeekendOrHolidayDate(year: number, month: number, day: number): boolean {
		let hollyDateCalendar: HollyDaysCalednar | undefined = this.hollyDaysCalednar
			.filter(
				(holiday: HollyDaysCalednar) =>
					holiday.year === year && holiday.month === month + 1 && holiday.day === day
			)
			.at(0);

		const date = new Date(year, month, day);

		if (hollyDateCalendar !== undefined) {
			return (
				hollyDateCalendar.day === day &&
				hollyDateCalendar.month === month + 1 &&
				hollyDateCalendar.year === year
			);
		}

		return date.getDay() === 0 || date.getDay() === 6;
	}

	private handleError(error: HttpErrorResponse): void {
		this.showMessage('error', error.error.httpStatus, error.error.message);
		this.loading = false;
	}

	showMessage(severity: string, summary: string, message: string) {
		this.messageService.add({
			key: 'tst',
			severity: severity,
			summary: summary,
			detail: message,
		});
	}

	createForm(): void {
		this.hollyDateForm = this.formBuilder.group({
			id: new FormControl(undefined),
			hollydayCalendarDate: new FormControl({ undefined, disabled: true }, [
				Validators.required,
			]),
			day: new FormControl(undefined, [Validators.required]),
			month: new FormControl(undefined, [Validators.required]),
			year: new FormControl(undefined, [Validators.required]),
			description: new FormControl(undefined, [
				Validators.required,
				Validators.maxLength(100),
				Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$'),
			]),
			entityCalendar: new FormControl(this.calendars, [Validators.required]),
		});
	}

	formControl(formControlName: string) {
		return this.hollyDateForm.get(formControlName);
	}

	saveHollyDate(): void {
		this.loading = true;
		this.submitted = true;
		if (this.hollyDateForm.invalid) {
			this.showMessage('error', 'Missing information', 'Please check the form.');
			this.loading = false;
			return;
		}

		this.hollyDayCalendarService
			.saveHollyDate(this.hollyDateForm)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this.showMessage('error', error.error.httpStatus, error.error.message);
					this.loading = false;
					return of(undefined);
				})
			)
			.subscribe((response) => {
				if (response) {
					this.hollyDaysCalednar = response.body;
					this.createForm();
					this.defaultDate = new Date(response.body[0].hollydayCalendarDate);
				}

				this.loading = false;
			});
	}

	deleteHollyDate(): void {
		this.loading = true;
		this.submitted = true;
		if (this.hollyDateForm.invalid) {
			this.showMessage('error', 'Missing information', 'Please check the form.');
			this.loading = false;
			return;
		}

		this.hollyDayCalendarService
			.deleteHollyDate(this.formControl('id')?.value)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this.showMessage('error', error.error.httpStatus, error.error.message);
					this.loading = false;
					return of(undefined);
				})
			)
			.subscribe((response) => {
				if (response) {
					this.hollyDaysCalednar = response?.body;
					this.defaultDate =
						response.body.length > 0
							? new Date(response.body[0].hollydayCalendarDate)
							: new Date();
					this.createForm();
				}

				this.loading = false;
			});
	}
}
