import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Calendars } from '../interfaces/calendars';
import { CalendarService } from '../services/calendar.service';

@Component({
	selector: 'app-holly-days-calendar-form',
	templateUrl: './holly-days-calendar-form.component.html',
	styleUrl: './holly-days-calendar-form.component.scss',
	providers: [MessageService],
})
export class HollyDaysCalendarFormComponent implements OnInit, OnChanges {
	calendarId: string = '0';
	calendar!: Calendars;

	loading: boolean = true;

	constructor(
		private calendarService: CalendarService,
		private router: Router,
		private activatedRouter: ActivatedRoute,
		private messageService: MessageService
	) {}
	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes);
	}

	ngOnInit(): void {
		this.calendarId = this.activatedRouter.snapshot.paramMap.get('calendarId') || '0';

		this.calendarService
			.getCalendarByI(this.calendarId)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this.showMessage('error', error.error.httpStatus, error.error.message);
					this.loading = false;
					return of(undefined);
				})
			)
			.subscribe((response) => {
				if (response) {
					this.calendar = response.body;
					this.loading = false;
				}
			});
	}

	showMessage(severity: string, summary: string, message: string) {
		this.messageService.add({
			key: 'tst',
			severity: severity,
			summary: summary,
			detail: message,
		});
	}
}
