import {
	Component,
	EventEmitter,
	input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';

import { Calendars } from '../interfaces/calendars';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, of } from 'rxjs';
import { CalendarService } from '../services/calendar.service';

@Component({
	selector: 'app-holly-days-calendar-add-upd',
	templateUrl: './holly-days-calendar-add-upd.component.html',
	styleUrl: './holly-days-calendar-add-upd.component.scss',
	providers: [MessageService],
})
export class HollyDaysCalendarAddUpdComponent implements OnInit, OnChanges {
	calendars = input.required<Calendars>();
	@Output() calendarAddUpdate = new EventEmitter<Calendars>();

	submitted = false;
	calendarForm!: FormGroup;
	loading: boolean = true;
	enableUpdateButton: boolean = true;
	calendar!: Calendars;

	constructor(
		private calendarService: CalendarService,
		private formBuilder: FormBuilder,
		private router: Router,
		private activatedRouter: ActivatedRoute,
		private messageService: MessageService
	) {}
	ngOnChanges(changes: SimpleChanges): void {
		if (this.calendars() !== undefined) {
			this.calendarForm.setValue(this.calendars());
			this.enableUpdateButton = false;
		}
		this.loading = false;
	}

	ngOnInit(): void {
		this.createForm();
	}

	showMessage(severity: string, summary: string, message: string) {
		this.messageService.add({
			key: 'tst',
			severity: severity,
			summary: summary,
			detail: message,
		});
	}

	createOrUpdateCalendar(): void {
		this.submitted = true;
		this.loading = true;

		if (this.calendarForm.invalid) {
			this.showMessage('error', 'Missing information', 'Please check the form.');
			this.loading = false;
			return;
		}

		this.calendarService
			.saveCalendar(this.calendarForm)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this.showMessage('error', error.error.httpStatus, error.error.message);
					this.loading = false;
					return of(undefined);
				})
			)
			.subscribe((response) => {
				this.showMessage(
					'info',
					'Holly Calendar',
					'Calendar ' + response?.body.entityCalendarShortName + ' has been updated'
				);
				this.calendarAddUpdate.emit(response?.body);
				this.loading = false;
			});
	}

	backTolist(): void {
		this.router.navigate(['entities/entity-setup/hollydays']);
	}

	createForm(): void {
		this.calendarForm = this.formBuilder.group({
			id: new FormControl(undefined),
			entityCalendarShortName: new FormControl(undefined, [
				Validators.required,
				Validators.maxLength(20),
				Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ_]+$'),
			]),
			entityCalendarName: new FormControl(undefined, [
				Validators.required,
				Validators.maxLength(50),
				Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$'),
			]),
		});
	}

	formControl(formControlName: string) {
		return this.calendarForm.get(formControlName);
	}
}
