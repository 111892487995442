import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultResponse } from '../../../../utils/interfaces/default-response';
import { WeekEndCalednarDays } from '../interfaces/week-end-calednar-days';
import { environment } from '../../../../../environments/environment';
import { DatePipe } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class WeekendCalendarDaysService {
	private baseUrl: string = environment.ok_backend.concat('/entity-setup/calendar/weekend');
	private date: Date = new Date();

	constructor(private httpClient: HttpClient) {}

	getAllWeekEndDaysByDate(): Observable<DefaultResponse<WeekEndCalednarDays[]>> {
		return this.httpClient.get<DefaultResponse<WeekEndCalednarDays[]>>(
			`${this.baseUrl}/${this.formatDate(new Date())}`
		);
	}
	formatDate(date: Date): string {
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	}
}
